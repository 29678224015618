import parsePhoneNumberFromString, {
  getCountryCallingCode,
  CountryCode,
  PhoneNumber as LibPhoneNumber,
} from 'libphonenumber-js/mobile'

import { trackEvent } from 'services/tracker'

/**
 * Utility class to get information about a phone number
 * Create an instance with `new PhoneNumber(number: string)`
 */
class PhoneNumber {
  parsedNumber?: LibPhoneNumber

  constructor(userInput: any) {
    if (typeof userInput !== 'string') {
      return
    }

    const parsed = parsePhoneNumberFromString(userInput, PhoneNumber.defaultCountryCode())

    this.parsedNumber = parsed
  }

  formatE164() {
    if (!this.parsedNumber) {
      return
    }

    return this.parsedNumber.format('E.164')
  }

  formatInternational() {
    if (!this.parsedNumber) {
      return
    }

    return this.parsedNumber.formatInternational()
  }

  isValidMobile() {
    if (!this.parsedNumber) {
      return false
    }

    return /MOBILE/.test(this.parsedNumber.getType() || '')
  }

  static defaultCountryCode(): CountryCode {
    return si?.config?.practice?.operating_country || 'DE'
  }

  static defaultCountryCallingCode = function () {
    return '+' + getCountryCallingCode(PhoneNumber.defaultCountryCode())
  }
}

/**
 * Gets the mobile phone number
 * Try with mobile first, if it's invalid try with regular phone
 */
export function getMobileNumber({ mobile = '', phone = '', tracking = false }) {
  !mobile && tracking && trackEvent('Messages', 'Attempted Mobile Number for SMS', 'empty')
  !phone && tracking && trackEvent('Messages', 'Attempted Phone Number for SMS', 'empty')

  const isMobileValid = new PhoneNumber(mobile).isValidMobile()
  const isPhoneValidMobile = new PhoneNumber(phone).isValidMobile()

  if (isMobileValid) {
    tracking && trackEvent('Messages', 'Attempted Mobile Number for SMS', 'valid')
    return mobile
  } else if (isPhoneValidMobile) {
    mobile && tracking && trackEvent('Messages', 'Attempted Mobile Number for SMS', 'invalid')
    tracking && trackEvent('Messages', 'Attempted Phone Number for SMS', 'valid')
    return phone
  }

  phone && tracking && trackEvent('Messages', 'Attempted Phone Number for SMS', 'invalid')

  return null
}

export default PhoneNumber
