import { trackPhoneNumberType } from 'patients/src/trackPhoneNumberType'
import { StorePracticePatient } from 'types/calendar/patient'

import { trackEvent } from './trackEvent'

const isEmptyProp = (o: Record<string, any>, k: string): boolean =>
  [undefined, null, ''].includes(o[k])

const getEmptyFields = (patient: StorePracticePatient) => {
  return Object.keys(patient).filter((field) => field in patient && isEmptyProp(patient, field))
}

// tracks which empty fields were changed
export function trackChangedEmptyFields(
  patientBeforeUpdate: StorePracticePatient,
  patientAfterUpdate: StorePracticePatient
): void {
  const emptyFields = getEmptyFields(patientBeforeUpdate)
  const changedFields = Object.keys(patientAfterUpdate).filter(
    (prop) => emptyFields.includes(prop) && !isEmptyProp(patientAfterUpdate, prop)
  )

  changedFields.length && trackEvent('Patients', 'Filled empty field', changedFields.join(', '))
}

// tracks phone number types
export function trackPhoneNumbersChanges(
  patientBeforeUpdate: StorePracticePatient | undefined,
  patientAfterUpdate: StorePracticePatient
) {
  const oldPhone = patientBeforeUpdate?.phone
  const oldMobile = patientBeforeUpdate?.mobile
  const newPhone = patientAfterUpdate?.phone
  const newMobile = patientAfterUpdate?.mobile

  if (oldPhone !== newPhone) {
    trackPhoneNumberType({
      oldNumber: oldPhone,
      newNumber: newPhone,
      field: 'phone',
      category: 'Patients',
    })
  }

  if (oldMobile !== newMobile) {
    trackPhoneNumberType({
      oldNumber: oldMobile,
      newNumber: newMobile,
      field: 'mobile',
      category: 'Patients',
    })
  }
}

export const trackPatient = (
  patientBeforeUpdate: StorePracticePatient | undefined,
  patientAfterUpdate: StorePracticePatient
) => {
  if (!patientBeforeUpdate) {
    trackEvent('Patients', 'Created Patient')
  } else {
    trackEvent('Patients', 'Updated existing Patient')
    trackChangedEmptyFields(patientBeforeUpdate, patientAfterUpdate)
  }

  trackPhoneNumbersChanges(patientBeforeUpdate, patientAfterUpdate)
}
