import { trackEvent } from 'services/tracker'
import PhoneNumber from 'utils/phoneNumber/phoneNumber'

interface TrackingInfo {
  oldNumber: string | null | undefined
  newNumber: string | null | undefined
  field: string
  category: string
}

/**
 * Tracks if a number is a mobile or a landline phone
 *
 * @param {string} oldNumber
 * @param {string} newNumber
 * @param {string} field mobile or phone
 * @param {string} category trackEvent category
 */
export function trackPhoneNumberType({ oldNumber, newNumber, field, category }: TrackingInfo) {
  if (newNumber && newNumber.length && oldNumber !== newNumber) {
    const isMobile = new PhoneNumber(newNumber).isValidMobile()
    trackEvent(category, `Added ${field} number`, isMobile ? 'is mobile' : 'is phone')
  }
}
