/**
 * Tracks an event using our user event collection infrastructure.
 *
 * @see config/initializers/rollout.rb
 *
 * @param category Event Category (Videos, Music, Games...)
 * @param action Action (Play, Pause, Duration, Add Playlist, Downloaded, Clicked...)
 * @param name Optional Event name
 * @param value Optional Event value
 */
export function trackEvent(category: string, action: string, name?: string, value?: number) {
  fetch('/api/monitoring/v1/events', {
    method: 'POST',
    body: JSON.stringify({
      event: {
        category,
        action,
        name,
        value,
      },
    }),
    headers: { 'Content-Type': 'application/json' },
  })
}
