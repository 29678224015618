import { trackEvent } from 'services/tracker'

const SAMEDI_APP_UPDATE_NOTIFICATION_ID = 'samedi-app-update-notification'

export default async function initializeAppUpdateNotification() {
  const updateDialog = document.getElementById(SAMEDI_APP_UPDATE_NOTIFICATION_ID)
  const checkForUpdatesFn = window.siApiUpdater?.checkForUpdates
  const installUpdateFn = window.siApiUpdater?.installUpdate

  if (updateDialog && checkForUpdatesFn && installUpdateFn) {
    const updateInfo = await checkForUpdatesFn()

    if (!updateInfo?.updateAvailable) {
      return
    }

    // update the notification text with dynamic elements
    const updateText = updateDialog.getElementsByClassName('alert-box__content')[0]
    const daysLeft = updateInfo.daysLeft < 0 ? 0 : updateInfo.daysLeft // samedi-app <= v17.5.2 can return negative numbers here

    if (daysLeft === 0) {
      // When the 10 days have passed, Samedi App must be auto updated.
      installUpdateFn()
      return
    }

    // hook the update button to trigger an update
    const installButton = updateDialog.getElementsByTagName('button')[0]
    if (installButton) {
      installButton.addEventListener('click', (ev) => {
        ev.preventDefault()
        trackEvent('Login Page', 'Install samedi-app Update', updateInfo.updateVersion, daysLeft)
        installUpdateFn()
      })
    }

    if (updateText) {
      const newText = updateText.innerHTML
        .replace('$VERSION', updateInfo.updateVersion)
        .replace('$DAYS', daysLeft.toString())
      updateText.innerHTML = newText
    }

    // show the UI
    updateDialog.classList.remove('hidden')
  }
}
